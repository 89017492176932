/* tslint:disable */
/* eslint-disable */
/**
 * Envrouter API
 * Envrouter API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface Application
 */
export interface Application {
    /**
     *
     * @type {string}
     * @memberof Application
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    'repositoryName'?: string;
    /**
     *
     * @type {string}
     * @memberof Application
     */
    'webhook'?: string;
}
/**
 *
 * @export
 * @interface Commit
 */
export interface Commit {
    /**
     *
     * @type {string}
     * @memberof Commit
     */
    'sha': string;
    /**
     *
     * @type {string}
     * @memberof Commit
     */
    'author'?: string;
    /**
     *
     * @type {string}
     * @memberof Commit
     */
    'message'?: string;
    /**
     *
     * @type {string}
     * @memberof Commit
     */
    'timestamp'?: string;
}
/**
 *
 * @export
 * @interface CredentialsSecretListItem
 */
export interface CredentialsSecretListItem {
    /**
     *
     * @type {string}
     * @memberof CredentialsSecretListItem
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof CredentialsSecretListItem
     */
    'type': string;
}
/**
 *
 * @export
 * @interface CredentialsSecretRequest
 */
export interface CredentialsSecretRequest {
    /**
     *
     * @type {string}
     * @memberof CredentialsSecretRequest
     */
    'key': string;
    /**
     *
     * @type {string}
     * @memberof CredentialsSecretRequest
     */
    'username': string;
    /**
     *
     * @type {string}
     * @memberof CredentialsSecretRequest
     */
    'password': string;
}
/**
 *
 * @export
 * @interface Environment
 */
export interface Environment {
    /**
     *
     * @type {string}
     * @memberof Environment
     */
    'name': string;
}
/**
 *
 * @export
 * @interface Instance
 */
export interface Instance {
    /**
     *
     * @type {string}
     * @memberof Instance
     */
    'type': InstanceTypeEnum;
    /**
     *
     * @type {string}
     * @memberof Instance
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof Instance
     */
    'environment': string;
    /**
     *
     * @type {string}
     * @memberof Instance
     */
    'application': string;
    /**
     *
     * @type {string}
     * @memberof Instance
     */
    'ref'?: string;
    /**
     *
     * @type {string}
     * @memberof Instance
     */
    'commitSha'?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum InstanceTypeEnum {
    Deployment = 'deployment'
}

/**
 *
 * @export
 * @interface InstancePod
 */
export interface InstancePod {
    /**
     *
     * @type {string}
     * @memberof InstancePod
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof InstancePod
     */
    'environment': string;
    /**
     *
     * @type {string}
     * @memberof InstancePod
     */
    'application': string;
    /**
     *
     * @type {string}
     * @memberof InstancePod
     */
    'ref'?: string;
    /**
     *
     * @type {string}
     * @memberof InstancePod
     */
    'commitSha'?: string;
    /**
     *
     * @type {boolean}
     * @memberof InstancePod
     */
    'ready': boolean;
    /**
     *
     * @type {string}
     * @memberof InstancePod
     */
    'phase': string;
    /**
     *
     * @type {string}
     * @memberof InstancePod
     */
    'createdTime': string;
    /**
     *
     * @type {string}
     * @memberof InstancePod
     */
    'startedTime'?: string;
    /**
     *
     * @type {boolean}
     * @memberof InstancePod
     */
    'started': boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof InstancePod
     */
    'parents'?: Array<string>;
}
/**
 *
 * @export
 * @interface Ref
 */
export interface Ref {
    /**
     *
     * @type {string}
     * @memberof Ref
     */
    'ref': string;
    /**
     *
     * @type {string}
     * @memberof Ref
     */
    'repository': string;
    /**
     *
     * @type {Commit}
     * @memberof Ref
     */
    'commit': Commit;
}
/**
 *
 * @export
 * @interface RefBinding
 */
export interface RefBinding {
    /**
     *
     * @type {string}
     * @memberof RefBinding
     */
    'environment': string;
    /**
     *
     * @type {string}
     * @memberof RefBinding
     */
    'application': string;
    /**
     *
     * @type {string}
     * @memberof RefBinding
     */
    'ref': string;
}
/**
 *
 * @export
 * @interface Repository
 */
export interface Repository {
    /**
     *
     * @type {string}
     * @memberof Repository
     */
    'name': string;
    /**
     *
     * @type {string}
     * @memberof Repository
     */
    'url': string;
    /**
     *
     * @type {string}
     * @memberof Repository
     */
    'credentialsSecret': string;
    /**
     *
     * @type {boolean}
     * @memberof Repository
     */
    'isNew'?: boolean;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Application
         * @param {string} name
         * @param {Application} [application]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationsNamePut: async (name: string, application?: Application, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiV1ApplicationsNamePut', 'name', name)
            const localVarPath = `/api/v1/applications/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(application, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CredentialsSecretsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/credentialsSecrets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CredentialsSecretsNameDelete: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiV1CredentialsSecretsNameDelete', 'name', name)
            const localVarPath = `/api/v1/credentialsSecrets/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Credential secret
         * @param {CredentialsSecretRequest} [credentialsSecretRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CredentialsSecretsPost: async (credentialsSecretRequest?: CredentialsSecretRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/credentialsSecrets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentialsSecretRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnvironmentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/environments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GitRefsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/git/refs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} sha
         * @param {string} repositoryName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GitRepositoriesRepositoryNameCommitsShaGet: async (sha: string, repositoryName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sha' is not null or undefined
            assertParamExists('apiV1GitRepositoriesRepositoryNameCommitsShaGet', 'sha', sha)
            // verify required parameter 'repositoryName' is not null or undefined
            assertParamExists('apiV1GitRepositoriesRepositoryNameCommitsShaGet', 'repositoryName', repositoryName)
            const localVarPath = `/api/v1/git/repositories/{repositoryName}/commits/{sha}`
                .replace(`{${"sha"}}`, encodeURIComponent(String(sha)))
                .replace(`{${"repositoryName"}}`, encodeURIComponent(String(repositoryName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InstancePodsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/instancePods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InstancesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [application]
         * @param {string} [environment]
         * @param {string} [ref]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RefBindingsGet: async (application?: string, environment?: string, ref?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refBindings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (application !== undefined) {
                localVarQueryParameter['application'] = application;
            }

            if (environment !== undefined) {
                localVarQueryParameter['environment'] = environment;
            }

            if (ref !== undefined) {
                localVarQueryParameter['ref'] = ref;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create RefBinding
         * @param {RefBinding} [refBinding]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RefBindingsPost: async (refBinding?: RefBinding, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/refBindings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refBinding, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get all repositories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepositoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/repositories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepositoriesNameDelete: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiV1RepositoriesNameDelete', 'name', name)
            const localVarPath = `/api/v1/repositories/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Credential secret
         * @param {Repository} [repository]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepositoriesPost: async (repository?: Repository, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/repositories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(repository, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ApplicationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Application>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ApplicationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update Application
         * @param {string} name
         * @param {Application} [application]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1ApplicationsNamePut(name: string, application?: Application, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Application>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1ApplicationsNamePut(name, application, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CredentialsSecretsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CredentialsSecretListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CredentialsSecretsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CredentialsSecretsNameDelete(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CredentialsSecretsNameDelete(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Credential secret
         * @param {CredentialsSecretRequest} [credentialsSecretRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CredentialsSecretsPost(credentialsSecretRequest?: CredentialsSecretRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CredentialsSecretListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CredentialsSecretsPost(credentialsSecretRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1EnvironmentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Environment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1EnvironmentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GitRefsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ref>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GitRefsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} sha
         * @param {string} repositoryName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1GitRepositoriesRepositoryNameCommitsShaGet(sha: string, repositoryName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Commit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1GitRepositoriesRepositoryNameCommitsShaGet(sha, repositoryName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InstancePodsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstancePod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InstancePodsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InstancesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Instance>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InstancesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} [application]
         * @param {string} [environment]
         * @param {string} [ref]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RefBindingsGet(application?: string, environment?: string, ref?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefBinding>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RefBindingsGet(application, environment, ref, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create RefBinding
         * @param {RefBinding} [refBinding]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RefBindingsPost(refBinding?: RefBinding, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefBinding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RefBindingsPost(refBinding, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary Get all repositories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepositoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Repository>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepositoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepositoriesNameDelete(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepositoriesNameDelete(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Credential secret
         * @param {Repository} [repository]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1RepositoriesPost(repository?: Repository, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Repository>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1RepositoriesPost(repository, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationsGet(options?: any): AxiosPromise<Array<Application>> {
            return localVarFp.apiV1ApplicationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Application
         * @param {string} name
         * @param {Application} [application]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1ApplicationsNamePut(name: string, application?: Application, options?: any): AxiosPromise<Application> {
            return localVarFp.apiV1ApplicationsNamePut(name, application, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CredentialsSecretsGet(options?: any): AxiosPromise<Array<CredentialsSecretListItem>> {
            return localVarFp.apiV1CredentialsSecretsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CredentialsSecretsNameDelete(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1CredentialsSecretsNameDelete(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Credential secret
         * @param {CredentialsSecretRequest} [credentialsSecretRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CredentialsSecretsPost(credentialsSecretRequest?: CredentialsSecretRequest, options?: any): AxiosPromise<CredentialsSecretListItem> {
            return localVarFp.apiV1CredentialsSecretsPost(credentialsSecretRequest, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1EnvironmentsGet(options?: any): AxiosPromise<Array<Environment>> {
            return localVarFp.apiV1EnvironmentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GitRefsGet(options?: any): AxiosPromise<Array<Ref>> {
            return localVarFp.apiV1GitRefsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} sha
         * @param {string} repositoryName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1GitRepositoriesRepositoryNameCommitsShaGet(sha: string, repositoryName: string, options?: any): AxiosPromise<Commit> {
            return localVarFp.apiV1GitRepositoriesRepositoryNameCommitsShaGet(sha, repositoryName, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InstancePodsGet(options?: any): AxiosPromise<Array<InstancePod>> {
            return localVarFp.apiV1InstancePodsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InstancesGet(options?: any): AxiosPromise<Array<Instance>> {
            return localVarFp.apiV1InstancesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} [application]
         * @param {string} [environment]
         * @param {string} [ref]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RefBindingsGet(application?: string, environment?: string, ref?: string, options?: any): AxiosPromise<Array<RefBinding>> {
            return localVarFp.apiV1RefBindingsGet(application, environment, ref, options).then((request) => request(axios, basePath));
        },
        /**
         * Create RefBinding
         * @param {RefBinding} [refBinding]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RefBindingsPost(refBinding?: RefBinding, options?: any): AxiosPromise<RefBinding> {
            return localVarFp.apiV1RefBindingsPost(refBinding, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get all repositories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepositoriesGet(options?: any): AxiosPromise<Array<Repository>> {
            return localVarFp.apiV1RepositoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepositoriesNameDelete(name: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1RepositoriesNameDelete(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Credential secret
         * @param {Repository} [repository]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1RepositoriesPost(repository?: Repository, options?: any): AxiosPromise<Repository> {
            return localVarFp.apiV1RepositoriesPost(repository, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1ApplicationsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1ApplicationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Application
     * @param {string} name
     * @param {Application} [application]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1ApplicationsNamePut(name: string, application?: Application, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1ApplicationsNamePut(name, application, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1CredentialsSecretsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1CredentialsSecretsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1CredentialsSecretsNameDelete(name: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1CredentialsSecretsNameDelete(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Credential secret
     * @param {CredentialsSecretRequest} [credentialsSecretRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1CredentialsSecretsPost(credentialsSecretRequest?: CredentialsSecretRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1CredentialsSecretsPost(credentialsSecretRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1EnvironmentsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1EnvironmentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1GitRefsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1GitRefsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} sha
     * @param {string} repositoryName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1GitRepositoriesRepositoryNameCommitsShaGet(sha: string, repositoryName: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1GitRepositoriesRepositoryNameCommitsShaGet(sha, repositoryName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InstancePodsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1InstancePodsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InstancesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1InstancesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} [application]
     * @param {string} [environment]
     * @param {string} [ref]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1RefBindingsGet(application?: string, environment?: string, ref?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1RefBindingsGet(application, environment, ref, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create RefBinding
     * @param {RefBinding} [refBinding]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1RefBindingsPost(refBinding?: RefBinding, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1RefBindingsPost(refBinding, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary Get all repositories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1RepositoriesGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1RepositoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1RepositoriesNameDelete(name: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1RepositoriesNameDelete(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Credential secret
     * @param {Repository} [repository]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1RepositoriesPost(repository?: Repository, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiV1RepositoriesPost(repository, options).then((request) => request(this.axios, this.basePath));
    }
}


